<template>
  <div class="section">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="返回" @click="goBack"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="商品标题名称：" disabled :value="goodsTitle" />
        <v-input label="下单人姓名" clearable v-model="searchParam.userName" />
        <v-input
          label="下单人联系方式"
          clearable
          v-model="searchParam.mobieNum"
        />
        <v-date-picker
          label="选择时间"
          v-model="time"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          startPlaceholder="开始时间"
          endPlaceholder="结束时间"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="取消订单" type="text" @click="toCancel(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getOrderListURL, refundOrderURL, queryRefundOrderURL } from "./api.js";

export default {
  data() {
    return {
      goodsTitle: "",
      searchParam: {
        id: "",
        userName: "",
        mobieNum: "",
        beginTime: "",
        endTime: "",
      },
      tableUrl: getOrderListURL,
      headers: [
        {
          prop: "receiverName",
          label: "下单人姓名",
        },
        {
          prop: "receiverPhone",
          label: "下单人联系方式",
        },
        {
          prop: "insertTime",
          label: "下单时间",
        },
        {
          prop: "price",
          label: "支付金额",
        },
      ],
    };
  },
  computed: {
    ...mapState("app", ["userInfo"]),
    time: {
      get() {
        return !this.searchParam.beginTime && !this.searchParam.endTime
          ? []
          : [this.searchParam.beginTime, this.searchParam.endTime];
      },
      set(val) {
        [this.searchParam.beginTime, this.searchParam.endTime] = val || [
          "",
          "",
        ];
      },
    },
  },
  created() {
    const { id, goodsTitle } = this.$route.query;
    this.goodsTitle = goodsTitle;
    this.searchParam.id = id;
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    toCancel(data) {
      this.$confirm("你确定要取消订单吗", "确定", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post(refundOrderURL, null, {
              params: {
                id: data.id,
                orderSn: data.orderSn,
              },
            })
            .then(async (res) => {
              if (res.code === 200) {
                await this.queryRefundOrder(res.data.outTradeNo);
                this.$message.success("取消成功");
                this.$refs.list.search();
              } else {
                this.$message.error(res.msg);
              }
            });
        })
        .catch(() => {
          // Message.error('禁用失败！');
        });
    },
    async queryRefundOrder(outTradeNo) {
      let res = await this.$axios.get(queryRefundOrderURL, {
        params: {
          outTradeNo,
          userId: this.userInfo.userId,
        },
      });
      return res;
    },
  },
};
</script>
<style lang="less" scoped>
.section {
  box-sizing: border-box;
  height: 100%;
}
</style>
